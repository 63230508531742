<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Site Owners List" page-icon="mdi-minecraft">
    <g-list-page
        :headers="headers"
        stateendpoint="siteOwner.siteOwners"
        :actions="actions"
        @loadData="loadData"
        @editItem="editItem"
        @deleteItem="deleteItem"
        @destroyItem="destroyItem"
        @restoreItem="restoreItem"
        @switchHandler="switchHandler"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(name)"
                @applySearch="handleSearch"
            />
          </v-col>
          <v-col cols="12" md="6" sm="4">
            <add-button
                text="Client"
                :toolbar="true"
                @onButtonClick="openPopUp"
                class="ma-2 justify-end"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>

    <component
        is="SiteOwnerPopup"
        ref="component"
        :basic-data="basicData"
        :item="editedItem"
        :show="showPopup"
        @closePopupHandler="closePopupHandler"
        @onPopupShow="openPopUp"
        @saveItemHandler="saveItemHandler"
    />
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
  </g-card>
</template>
<script>
import ListMixins from "../../components/mixins/list/List";
import SiteOwnerPopup from "../../components/popups/SiteOwnerPopup";
import GCard from "../../components/GCard";
import GListPage from "../../components/list/GListPage";
import ConfirmPopup from "../../components/popups/ConfirmPopup";
import AddButton from "../../components/buttons/AddButton";
import GPSearch from "../../components/GPSearch";
import PageOverlay from "../../components/PageOverlay";

export default {
  name: 'SiteOwners',
  components: {
    PageOverlay,
    GPSearch,
    AddButton,
    ConfirmPopup,
    GListPage,
    GCard,
    SiteOwnerPopup
  },
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Site Owners | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  mixins: [ListMixins],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          align: 'center',
          value: 'email'
        },
        {
          text: 'Status',
          cellClass: "center-switch",
          align: "center",
          value: 'isActive'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      actions: {
        load: 'siteOwner/load',
        store: 'siteOwner/store',
        update: 'siteOwner/update',
        remove: 'siteOwner/remove',
        destroy: 'siteOwner/destroy',
        restore: 'siteOwner/restore',
      },
      basicData: {
        isActive: true
      }
    }
  },
}
</script>
